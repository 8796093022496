<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!--목표년도-->
          <c-datepicker
            :editable="editable"
            type="year"
            default="today"
            label="목표년도"
            name="targetYear"
            v-model="searchParam.targetYear"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="MAIN_SAFETY_HEALTHY_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="safetyHealthyTypeCd"
            label="구분"
            v-model="searchParam.safetyHealthyTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 해당부서 -->
          <c-dept
            type="search"
            label="해당부서"
            name="deptCd"
            :isFirstValue="false"
            v-model="searchParam.deptCd"
          />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <!--안전보건 목표-->
        <c-table
          ref="table"
          title="관계법령 의무사항 이행 목표"
          :merge="grid.merge"
          :columns="grid.columns"
          :data="grid.data"
          :gridHeight="grid.height"
          :editable="editable"
          :usePaging="false"
          :isExcelDown="false"
          :filtering="true"
          :isFullScreen="false"
          :columnSetting="false"
          :selection="popupParam.type"
          rowKey="saiSafetyHealthyId"
          @rowDblclick="rowDblclick"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <!--검색-->
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
              <!--선택-->
              <c-btn v-if="editable" label="LBLSELECT" icon="check" @btnClicked="select" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safety-healthy-mst-popup',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        type: 'multiple',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        targetYear: '',
        useFlag: 'Y',
        deptCd: '',
        safetyHealthyTypeCd: null,
      },
      editable: true,
      grid: {
        merge: [
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            //사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:130px',
            sortable: true,
          },
          {
            name: 'targetYear',
            field: 'targetYear',
            label: '목표년도',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'safetyHealthyTypeName',
            field: 'safetyHealthyTypeName',
            label: '구분',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'targetName',
            field: 'targetName',
            //안전보건 목표
            label: 'LBL0010046',
            align: 'left',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            //해당부서
            label: 'LBL0010019',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
        ],
        data: [],
        height: '400px',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.mainsafetyHealthy.base.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '하나 이상 선택하세요.',  // 년도별 목표를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>